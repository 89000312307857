a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  color: white; 
  font-weight:bolder;
}

.navbar {
    background-color: #D04900;
    border-bottom: solid; 
    border-color: #EBBAA0; 
}

.text-light:hover {
    font-weight: bold;
}

a.navbar-brand:hover {
    color: #EBBAA0;
}

.mr-2{
    color: #EBBAA0;
}

.mr-2:hover {
    background-color: #EBBAA0;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
