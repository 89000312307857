
a {
  color: #0366d6;
}

input {
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    border-width: 5px;
    border-color: white;
    border-style: solid;
}

.question-mark {
    cursor: pointer;
}

.number-of-groups {
    width: 13%;
    height:25px;
    margin-top: 0px;
    border-radius: 6px;
    border-width: 0px;
}

.mark-scenario {
    margin: 20px;
}

.scenario-delete {
    color: #D04900;
    font-weight: bold;
    cursor: pointer;
}

.role-delete {
    color: #D04900;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
}

h4 {
    font-size: 20px;
}

.role-scenario .scenario-overview {
    font-size: 17px;
    padding-top: 10px;
    margin: 20px;
}

.role-scenario h4 {
    padding-top: 15px;
}

.role-scenario .scenario-overview span {
    font-size: 26px;
    padding-top: 10px;
    cursor: pointer;
    font-weight: bolder;
}

.number-count {
    background-color: white;
    color: #3B5468;
    border-radius: 8px;
    margin-left: 3px;
    margin-bottom: 20px;
}

.role-scenario {
    background-color: white;
    color: #3B5468;
    border-radius: 8px;
    margin-bottom: 20px;
}

.steps {
    list-style-type: none;
    padding-bottom: 20px;
    padding-top: 20px;
    margin: 0px;
    
    text-align: center;
    font-size: 14px;
    
    border-radius: 8px 8px 0px 0px;
}

.steps li {
    display: inline-block;
    margin-right: 18px;
    color: #999999;
    font-weight: 400;
}

.steps li.is-active {
    border-bottom: 1px solid #EBBAA0;
    color: #EBBAA0;
    font-weight: 700;
}

body {
    background-color: #414148;
    color: white;
    font-family: "Baskerville", Helvetica;
}

h1,h2,h3 {
    font-weight:bold; 
}

.prepareCard:hover{

    box-Shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

select {
    border-radius: 10px;
    border-width: 5px;
    border-color: white;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #00759E;
    border-color: #00759E;
}

.btn-info {
    color: #fff;
    background-color: #D04900;
    border-color: #D04900;
}

.btn-danger {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545;
}

.btn-primary:hover {
   background-color: #526e85;
   border-color: #526e85;
}

.running-sim-txt {
    font-size: 34px;
}

.message-box {
    height: 200px;
}

.truncate {
    margin: 10% 0 0 0;
}

.role-txt-area {
    width: 600px;
}

.sim-dropdown {
    max-width: 100%;
}


@media (max-width: 1190px) {
    .message-box {
        height: 60px;
    }

    .running-sim-txt {
        font-size: 22px;
    }

    .header-txt {
        font-size: 20px;
    }

    .scenario-list {
        max-height: 250px;
        overflow-y: scroll;
    }

    .list-container {
        max-height: 450px;
        overflow-y: scroll;
    }

    .truncate {
        margin: 0 0 0 0;
        margin-bottom: 0px;
        text-align:end;
        order: -1;
    }

    .sim-dropdown {
        max-width: 408px;
    }
}


@media (max-width: 600px) {
    .role-txt-area {
        width: 250px;
    }

}




